<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="fas fa-stopwatch mr-1"></i>
                        Expedientes por hora
                    </h3>
                    <i class="fas fa-info" style="float:right" @click="toggle($event)"></i>

                </div><!-- /.card-header -->
                <div class="card-body">
                    <!-- Morris chart - Sales -->
                    <div class="chart" style="position: relative; ">
                        <Chart type="line" :data="basicData" :options="basicOptions" />
                    </div>
                    <OverlayPanel ref="info1">
                        <h4 style="text-align:center">Expedientes por hora</h4>
                        <p>Indica la <strong>diferencia</strong> del número de expedientes en cada estado<br>
                            Ejemplo: Si se finalizan/facturan 2 expedientes y no se ha asignado ninguno todavía<br>
                            aparecerán 2 expedientes finalizados y 2 activos menos.</p>

                    </OverlayPanel>
                </div><!-- /.card-body -->
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="far fa-chart-bar mr-1"></i>
                        Expedientes totales
                    </h3>
                    <i class="fas fa-info" style="float:right" @click="toggledos($event)"></i>

                </div><!-- /.card-header -->
                <div class="card-body">
                    <!-- Morris chart - Sales -->
                    <div class="chart" style="position: relative; ">
                        <Chart type="line" :data="basicDatados" :options="basicOptions" />
                    </div>
                    <OverlayPanel ref="info2">
                        <h4 style="text-align:center">Expedientes totales</h4>
                        <p>Cantidad de expedientes en estado<br>
                            activo/eliminado/finalizado por día</p>

                    </OverlayPanel>
                </div><!-- /.card-body -->
            </div>
        </div>
    </div>
</template>
<script>
import OverlayPanel from 'primevue/overlaypanel';
export default ({
    components: {
       
        OverlayPanel, 
    },
    data() {
        return {
           
        
            basicData: {
                labels: '',
                datasets: [
                    {
                        label: 'Activos',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                    {
                        label: 'Eliminados',
                        data: '',
                        fill: false,
                        borderColor: '#FFA726',
                        tension: .4
                    },
                    {
                        label: 'Finalizados',
                        data: '',
                        fill: false,
                        borderColor: '#CB1010',
                        tension: .4
                    }
                ]
            },
            basicDatados: {
                labels: '',
                datasets: [
                    {
                        label: 'Activos',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                ]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        }
    },
    props:['datosestadisticas'],
    methods: {
        toggle(event) {
            this.$refs.info1.toggle(event);
        },
        toggledos(event) {
            this.$refs.info2.toggle(event);
        },

    },
    watch: {
        datosestadisticas(value){               
            this.estadisticas = value; 
            this.basicData.labels = this.estadisticas.graficas.expedientes_hora.labels;
            this.basicDatados.labels = this.estadisticas.graficas.expedientes_totales.labels;
            this.basicData.datasets[0].data = this.estadisticas.graficas.expedientes_hora.activos;
            this.basicDatados.datasets[0].data = this.estadisticas.graficas.expedientes_totales.activos;
        }
    },
})

</script>